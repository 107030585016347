<template>
  <v-app>
    <v-main>
      <div class="centered">
        <v-responsive :aspect-ratio="41 / 35">
          <div class="video-block">
            <v-responsive :aspect-ratio="16 / 9">
              <transition name="fade">
                <div class="welcome-holder fill-height">
                  <img
                    src="@/assets/show-poster.png"
                    width="100%"
                    alt="Whodunnit at the Coliseum show poster"
                    class="welcome-img"
                  />
                </div>
              </transition>
            </v-responsive>
          </div>
          <div class="options">
            <v-responsive :aspect-ratio="5 / 1">
              <v-container fill-height>
                <v-row justify="center" class="earlylate">
                  <div v-if="$store.state.earlylate == 'early'">
                    <span
                      >You're in the right place, you're just a little early!
                      <br />
                      Your ticket starts on
                      <strong>{{ nicedate }}</strong> and you have 48hrs
                      access.</span
                    >
                  </div>
                  <div v-else-if="$store.state.earlylate == 'late'">
                    <span
                      >Uh oh! Your ticket has now expired.<br />
                      If this is an error, please email
                      boxoffice@coliseum.org.uk</span
                    >
                  </div>
                  <div v-else>
                    <span
                      >Uh oh! Something went wrong! Please try loading the link
                      from your ticket again.<br />
                      If this error continues, please email
                      boxoffice@coliseum.org.uk</span
                    >
                  </div>
                </v-row>
              </v-container>
            </v-responsive>
          </div>
        </v-responsive>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: function() {
    return {
      starthover: false,
      howitworks: false
    };
  },
  computed: {
    nicedate() {
      return new Date(this.$store.state.bookedfor).toDateString();
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  height: 100vh;
  position: relative;
  display: block;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(../assets/newspaper-no-lines.jpg) no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    z-index: 0;
    filter: invert(20%) sepia(20%) brightness(0.3) blur(8px);
    -webkit-filter: invert(20%) sepia(20%) brightness(0.3) blur(8px);
    transition: 1s ease-out;
  }

  .video-block {
    position: relative;
    top: 7%;
    margin: 7px 7px 7px 14px;
  }
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-52%));
  min-width: 55.2%;
  z-index: 1;
}

.download-programme {
  display: block;
  position: absolute;
  top: 4px;
  right: -7px;
  width: 42%;
  z-index: 100;
  transition: 1s ease-out;

  &:hover {
    filter: none !important;
    -webkit-filter: none !important;
  }
}

.welcome-holder {
  box-sizing: border-box;
  position: relative;
  width: 100%;

  img {
    position: absolute;
    left: 0;
    top: 0;
  }

  .welcome-img {
    transition: 0.8s;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
  }
}

.options {
  position: relative;
  transition: 1s ease-out;
  top: 4%;
  padding-top: 0.6vh;
  text-align: center;
  padding-top: 30px;
}

.earlylate {
  color: #fff;
  font-family: "Franchise";
  font-size: 2.1em;

  strong {
    color: #d61b21;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
